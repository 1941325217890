import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo/seo';
import { Container, Stack, Col, Row } from "react-bootstrap";
import './styles/index.scss';
import { StructuredText } from "react-datocms";

import { UseTheme } from '../context/ThemeContext';

const technology = ({ data }) => {

  const { theme } = UseTheme();

  return (
    <div className="background-theme">
      <Container className="pt-5 pb-5 internal-pages-common technology">
        <h1>{data.allDatoCmsTechnology.nodes[0].slugPage.title}</h1>
        <Stack className="card-long">
          <img alt="card-long-first" src={data.allDatoCmsTechnology.nodes[0].bannerPage.url} />
        </Stack>
        <Stack>
          <h5 className='mt-4 text-center title-700'>
            {data.allDatoCmsTechnology.nodes[0].pageTitle}
          </h5>
          <h6 className='mb-4 text-center title-400'>
            {data.allDatoCmsTechnology.nodes[0].pageSubtitle}
          </h6>
        </Stack>
        <Stack>
          <Row> 
            {
              data.allDatoCmsTechnology.nodes[0].itemsIcons?.map((itemsIcons, index) =>
                <Col className="mb-3 technology-columns " xs={12} md={4} key={index}>
                  {theme === 'dark' ?
                    (<img alt="card-long-first" src={itemsIcons.imageDarkMode.url} />)
                    :
                    (<img alt="card-long-first" src={itemsIcons.image.url} />)
                  }
                  <h4 className='mt-3 mb-0 title-700'>{itemsIcons.title}</h4>
                  <p>{itemsIcons.description}</p>
                </Col>
              )
            }
            <span className='titleAndParagraph'>
              <h6 className='mt-3 mb-3 title-600'>
                {data.allDatoCmsTechnology.nodes[0].titleAndParagraph[0].title}
              </h6>
              <span dangerouslySetInnerHTML={{ __html: data.allDatoCmsTechnology.nodes[0].titleAndParagraph[0].paragraph}}></span>
            </span>
          </Row>
        </Stack>

      </Container>
      <Stack>
        <div className='blue-footer'>
          <Container>
            <Row>
              <Col className="blog-paragraph" xs={12} md={8}>
                <p>{data.allDatoCmsTechnology.nodes[0].videoSection[0].paragraph1}</p>
                <p>{data.allDatoCmsTechnology.nodes[0].videoSection[0].paragraph2}</p>
              </Col>
              <Col xs={12} md={4}>
                <iframe title='videoUrl' width="420" height="315" src={data.allDatoCmsTechnology.nodes[0].videoSection[0].videoUrl}>
                </iframe>
              </Col>
            </Row>
          </Container>
        </div>
      </Stack>
    </div>
  )
}

export default technology

export const Head = ({ data }) => (
  <Seo
    title="Technology"
    description={data.allDatoCmsTechnology.nodes[0].seo.description}
    image={data.allDatoCmsTechnology.nodes[0].seo.image.url}
  />
)

export const query = graphql`
query TechnologyQuery {
  allDatoCmsTechnology {
    nodes {
      seo {
        title
        description
        image {
          url
        }
      }
      slugPage {
        title
        slug
      }
      bannerPage {
        url
      }
      pageTitle
      pageSubtitle
      itemsIcons {
        title
        description
        image {
          url
        }
        imageDarkMode{
          url
        }
      }
      titleAndParagraph {
        title
        paragraph
      }
      videoSection {
        paragraph1
        paragraph2
        videoUrl
      }
    }
  }
}
` 

// imageDarkMode {
//   url
// }