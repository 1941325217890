import * as React from 'react'
// import { useStaticQuery, graphql } from 'gatsby'

const Seo = ({ title, description, image }) => {

  return (
    <>
      <title>{title} | Digital Trading Cards (DTC)</title>
      <meta property="og:title" content={title + " | Digital Trading Cards (DTC)"}  />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="image" content={image} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="1024" />
      <meta property="og:image:height" content="969" />
      <meta name="twitter:title" content="Digital Trading Cards (DTC)" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:creator" content="Digital Trading Cards (DTC)" />
      <meta name="twitter:card" content={image} />
      <meta name="twitter:site" content="@digitaltradingcards" />
      <meta name="description" content={description} />

      <link rel="shortcut icon" href={image} type="image/x-icon" />

      <meta property="article:modified_time" content="2020-03-06T15:07:14Z" />
      <meta property="og:locale" content="en" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Digital Trading Cards (DTC)" />

      <meta name="keywords" content="HTML, CSS, JavaScript" />
      <meta name="author" content="Digital Trading Cards (DTC)" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </>
  )
}
export default Seo